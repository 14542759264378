import { ScrollSlider } from './scrollSlider/ScrollSlider';
import Swiper, { EffectFade } from 'swiper';

window.addEventListener('load', () => {
  initSlider();
})

// Swiper.use(EffectFade);

function initSlider() {
  if (window.innerWidth > 1279) {
    document.querySelector('[data-brand-slider]').classList.add('_scroller');
    new ScrollSlider(document.querySelector('[data-brand-slider]'));
  } else {
    const swiper = new Swiper(document.querySelector('[data-brand-slider-container]'), {
      // effect: 'fade',
      // // fadeEffect: {
      // //   crossFade: true,
      // // },
      pagination: {
        el: document.querySelector('[data-brand-slider-pagination]'),
      },
    });
  }
}