function initHash() {
  if (window.location.hash) {
    const links = document.querySelectorAll('[data-tag]');
    Array.from(links).forEach((link) => {
      if (link.dataset.tag === window.location.hash) {
        link.click();
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', initHash);