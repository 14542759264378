import Swiper, { Navigation }  from 'swiper';
import 'swiper/swiper.scss';

window.addEventListener('load', () => {
  const style = getComputedStyle(document.body);
  const leftNav = document.querySelector('[data-producer-slider-left]');
  const rightNav = document.querySelector('[data-producer-slider-right]');
  const swiper = new Swiper('[data-producer-slider]', {
    modules: [Navigation],
    freeMode: true,
    slidesPerView: 'auto',
    spaceBetween: 50,
    autoplay: true,
    speed: 800,
    // momentum: true,
    // centeredSlides: true,
    // centeredSlidesBounds: true,
    navigation: {
      prevEl: leftNav,
      nextEl: rightNav,
    },
  });
});