//
class StickyHeader {
  config = {
    enableWidth: 1279,
    hamburgerSelector: '[data-hamburger]', // Если есть гамбурге цепляем его селектор сюда
    menuSelector: '[data-menu]', // Если нужно на меню вешать класс от гамбургера - сюда пихаем селектор его
    desktopDetect: false, // Если нужно навешивать класс на десктопную шапку, когда страница не в нуле - ставим тру
  };

  enableMobile = false;
  header = undefined;
  hamburger = undefined;
  menu = undefined;

  lastScrollPosition = 0;
  currentOffsetHeader = 0;
  currentHeaderHeight = 0;

  menuLinks = [];

  constructor(selector, config) {
    this.config = Object.assign({}, this.config, config);
    this.header = document.querySelector(selector);
    if (!this.header) {
      throw new Error(`Header element not found by selector ${selector}`)
    }

    if (this.config.hamburgerSelector) {
      this.hamburger = document.querySelector(this.config.hamburgerSelector);
      if (!this.hamburger) {
        throw new Error(`Hamburger element not found by selector ${this.config.hamburgerSelector}`)
      }
    }

    if (this.config.menuSelector) {
      this.menu = document.querySelector(this.config.menuSelector);
      if (!this.menu) {
        throw new Error(`Hamburger element not found by selector ${this.config.menuSelector}`)
      }
    }

    this.menuLinks = Array.from(this.header.querySelectorAll('[data-menu-link]'));

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.setLastScrollPosition(scrollTop);
    this.defineHeaderHeight();
    this.init();
  }

  init() {
    if (this.isMobile()) {
      this.enableMobileScroll();
    } else {
      this.setDisabledMobile();

      if (this.config.desktopDetect) {
        this.enableDesktopScroll();
      }
    }

    this.bindHamburger();
    this.bindResize();
    this.bindLinks();
    this.setStaticObject();
    this.checkSticky(true);
  }


  mobileListener(e) {
    if (StickyHeader.instance) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const instance = StickyHeader.instance;
      const lastScrollPosition = instance.lastScrollPosition;

      if (lastScrollPosition < scrollTop) {
        instance.translateHeaderTop(scrollTop - lastScrollPosition);
        instance.closeMenu();
        instance.checkSticky(true);
      } else {
        instance.translateHeaderBottom(lastScrollPosition - scrollTop);
        instance.checkSticky(false);
      }

      instance.setLastScrollPosition(scrollTop)
    }
  }

  translateHeaderTop(diff) {
    if (this.currentOffsetHeader < this.currentHeaderHeight) {
      this.currentOffsetHeader += diff;
      if (this.currentOffsetHeader > this.currentHeaderHeight) {
        this.currentOffsetHeader = this.currentHeaderHeight;
      }
      this.translateHeader();
    }
  }

  translateHeaderBottom(diff) {
    if (this.currentOffsetHeader > 0) {
      this.currentOffsetHeader -= diff;
      if (this.currentOffsetHeader < 0) {
        this.currentOffsetHeader = 0;
      }
      this.translateHeader();
    }
  }

  translateHeader() {
    this.header.style.transform = `translate3D(0, ${-this.currentOffsetHeader}px, 0)`;
  }

  desktopListener(e) {
    if (StickyHeader.instance) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const instance = StickyHeader.instance;
      const lastScrollPosition = instance.lastScrollPosition;

      if (lastScrollPosition < scrollTop) {
        instance.checkSticky(true);
      } else {
        instance.checkSticky(false);
      }

      StickyHeader.instance.setLastScrollPosition(scrollTop)
    }
  }

  checkSticky(bottomDirection) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const header = StickyHeader.instance.header;
    const activateHeight = this.enableMobile ? this.currentHeaderHeight : 0;
    if (bottomDirection && scrollTop > activateHeight && !header.classList.contains('_sticky')) {
      header.classList.add('_sticky');
    } else if (!bottomDirection && scrollTop <= 0 && header.classList.contains('_sticky')) {
      header.classList.remove('_sticky');
    }
  }

  switchCheck () {
    if (this.isMobile() && !this.enableMobile) {
      this.turnOn();
    } else if (!this.isMobile() && this.enableMobile) {
      this.turnOf();
    }
  }

  turnOn() {
    if (this.config.desktopDetect) {
      this.disableDesktopScroll();
    }

    this.enableMobileScroll();
    this.resetHeaderPosition();
  }

  turnOf() {
    this.disableMobileScroll();

    if (this.config.desktopDetect) {
      this.enableDesktopScroll();
    }

    this.resetHeaderPosition();
  }

  resetHeaderPosition() {
    this.currentOffsetHeader = 0;
    this.translateHeader();
  }

  bindHamburger() {
    const instance = this;
    this.hamburger.addEventListener('click', (e) => {
      e.preventDefault();
      if (instance.hamburger.classList.contains('_open')) {
        instance.closeMenu();
      } else {
        instance.openMenu();
      }
    });
  }

  bindLinks() {
    const instance = this;
    this.menuLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        if (this.hamburger.classList.contains('_open')) {
          instance.closeMenu();
        }
      });
    });
  }

  openMenu() {
    this.header.classList.add('_open');
    this.hamburger.classList.add('_open');
    if (this.menu) {
      this.menu.classList.add('_open');
    }
  }

  closeMenu() {
    this.header.classList.remove('_open');
    this.hamburger.classList.remove('_open');
    if (this.menu) {
      this.menu.classList.remove('_open');
    }
    document.body.style.overflow = '';
    document.body.style.height = '';
  }


  enableMobileScroll() {
    const instance = this;
    document.addEventListener('scroll', instance.mobileListener);
    this.setEnabledMobile();
  }

  enableDesktopScroll() {
    const instance = this;
    document.addEventListener('scroll', instance.desktopListener);
  }

  disableMobileScroll() {
    const instance = this;
    document.removeEventListener('scroll', instance.mobileListener);
    this.setDisabledMobile();
  }

  disableDesktopScroll() {
    const instance = this;
    document.removeEventListener('scroll', instance.desktopListener);
  }

  setEnabledMobile() {
    this.enableMobile = true;
  }

  setDisabledMobile() {
    this.enableMobile = false;
  }

  isMobile() {
    return window.innerWidth <= this.config.enableWidth;
  }

  bindResize() {
    const instance = this;
    window.addEventListener('resize', (e) => {
      instance.switchCheck();
    });
  }

  setLastScrollPosition(scrollY) {
    this.lastScrollPosition = scrollY;
  }

  setStaticObject() {
    StickyHeader.instance = this;
  }

  defineHeaderHeight() {
    this.currentHeaderHeight = this.header.getBoundingClientRect().height;
  }
}

function initHeader() {
  const rootStyles = getComputedStyle(document.documentElement);
  const mediumWidth = rootStyles.getPropertyValue('--medium-inner-width') || 1279;

  setTimeout(() => {
    try {
      const header = new StickyHeader('[data-header]', {
        hamburgerSelector: '[data-hamburger]',
        menuSelector: '[data-menu]',
        mediumInnerWidth: mediumWidth,
        desktopDetect: true
      });
    } catch (e) {
      console.log(e);
    }
  }, getPrepareTimeout());
}


function getPrepareTimeout() {
  let timeout = 0;
  if (process.env.NODE_ENV !== 'production') {
    timeout = 2000;
  }
  return timeout;
}

document.addEventListener('DOMContentLoaded', initHeader);