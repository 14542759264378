import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

window.addEventListener('load', () => {
  const swiper = new Swiper(document.querySelector('[data-map-slider]'), {
    pagination: {
      el: document.querySelector('[data-map-slider-pagination]'),
    },
    navigation: {
      nextEl: '[data-map-slider-next]',
      prevEl: '[data-map-slider-prev]',
    },
  });
});