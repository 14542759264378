/**
 * Елемент аккордиона
 *
 * ОБЯЗАТЕЛЬНО:
 * [data-accordion-element] (общий элемент) - должен быть на элементе
 *
 * [data-accordion-link] (переключатель) - должен быть на ссылке внутри общего элемента
 *
 * [data-accordion-block] (прячущий элемент (overflow: hidden)) -
 *  должен содержать только одного потомка и быть внутри общего элемента
 *
 * ДОПОЛНИТЕЛЬНО:
 * [data-is-opened="true"] - можно повесить на элемент, что бы он раскрылся при инициализации
 *
 * ПОБОЧНО:
 * ._opened - вешается на элемент при раскрытии и убирается при закрытии
 *
 * ElementOpen - событие отправляемое на элемент при раскрытии
 *
 * ElementClose - событие отправляемое на элемент при закрытии
 *
 * ПРИМЕР РАЗМЕТКИ ЭЛЕМЕНТА:
 *  <li data-accordion-element data-is-opened="true">
 *    <a data-accordion-link>
 *      Развернуть
 *    </a>
 *
 *    <div data-accordion-block>  <-- задать транзишен на высоту
 *      <div>
 *        (много текста)
 *      </div>
 *    </div
 *  </li>
 */
class Element {
  constructor(element) {
    this.element = element;

    this.links = this.element.querySelectorAll('[data-accordion-link]');
    this.block = this.element.querySelector('[data-accordion-block]');
    this.childrenElements = this.element.querySelectorAll('[data-accordion-element]');

    this.parentBlock = this.element.closest('[data-accordion-block]');
    if (this.parentBlock) {
      this.parentHeight = undefined;
    }

    if (!this.links.length) {
      throw new Error('Element by selector [data-accordion-link] not found');
    }
    if (!this.block) {
      throw new Error('Element by selector [data-accordion-block] not found');
    }

    if (this.block.childElementCount !== 1) {
      throw new Error('Element by selector [data-accordion-block] must contain 1 child');
    }

    this.block.style.overflow = 'hidden';
    this.content = this.block.children[0];
    this.defineHeight();
    this.addListeners();
    if (!!this.element.dataset.isOpened) {
      this.setOpenedProperties();
    } else {
      this.setCloseProperties();
    }
  }

  /**
   * Установка целевой высоты равной высоте контента блока
   */
  defineHeight() {
    this.targetHeight = this.content.clientHeight;
  }

  /**
   * Развернуть и отправить событие
   */
  open() {
    this.setOpenedProperties();
    this.element.dispatchEvent(new CustomEvent('ElementOpen'));
    if (this.parentBlock) {
      this.parentHeight = this.parentBlock.style.height;
      this.parentBlock.style.height = 'auto';
    }
  }

  /**
   * Свернуть и отправить событие
   */
  close() {
    if (this.childrenElements.length) {
      this.closeChildren();
      setTimeout(() => {
        this.setCloseProperties();
      }, 320);
    } else if (this.parentBlock) {
      this.setCloseProperties();
      setTimeout(() => {
        this.parentBlock.style.height = this.parentHeight;
      }, 300);
    } else {
      this.setCloseProperties();
    }

    this.element.dispatchEvent(new CustomEvent('ElementClose'));
  }

  closeChildren() {
    this.childrenElements.forEach((element) => {
      element.dispatchEvent(new CustomEvent('closeAccordion'));
    });
  }

  /**
   * Развернуть
   */
  setOpenedProperties() {
    this.block.style.height = '';
    this.isOpened = true;
    this.element.classList.add('_opened');
  }

  /**
   * Свернуть
   */
  setCloseProperties() {
    this.block.style.height = '';
    this.isOpened = false;
    this.element.classList.remove('_opened');
  }

  /**
   * Отслеживание нажатия по переключателю
   */
  addListeners() {
    const instance = this;
    this.links.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        if (instance.isOpened) {
          instance.close();
        } else {
          instance.open();
        }
      });
    });

    this.element.addEventListener('closeAccordion', (e) => {
      this.close();
    });
  }

  onResize() {
    this.defineHeight();
    if (this.isOpened) {
      this.setOpenedProperties();
    }
  }
}

export default Element;