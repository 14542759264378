import Swiper from 'swiper';
import 'swiper/swiper.scss';

window.addEventListener('load', () => {
  const style = getComputedStyle(document.body);
  const swiper = new Swiper('[data-service-slider]', {
    freeMode: true,
    slidesPerView: 2.2,
    momentum: true,
    centeredSlides: true,
    centeredSlidesBounds: true,
  });
});