require('../scss/app.scss');
require('./svg/svg');

window.noZensmooth = true;

require('./common/true-vh');
require('./common/goal');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common/floating-field');
require('./common/need-animation');
require('./common/webp-polyfill');

require('../fonts/HelveticaNeue/stylesheet.css');

require('./accordion/initializator');

require('./sliders/service-slider');
require('./sliders/map-slider');
require('./sliders/brand-slider');
require('./sliders/producer-slider');

require('./field/phone');
require('../js/sticky/sticky');

// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');
// require('../vue/app.js');
require('../js/header/header-closer');
require('./hash');