function initHeaderCloser() {
  const header = document.querySelector('[data-header]');

  if (header) {
    const hamburger = document.querySelector('[data-hamburger]');
    const menu = header.querySelector('[data-menu]');
    const headerCloseLinks = header.querySelectorAll('[data-close-header]');

    headerCloseLinks.forEach((link) => {
      link.addEventListener('click', () => {
        if (header.classList.contains('_open')) {
          header.classList.remove('_open');
        }

        if (hamburger.classList.contains('_open')) {
          hamburger.classList.remove('_open');
        }

        if (menu.classList.contains('_open')) {
          menu.classList.remove('_open');
        }
      });
    });
  }
}

initHeaderCloser();